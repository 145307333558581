.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

* {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #9A8B8B;
}

.pc {
  width: min(450px, 100vw);
  margin: 0 auto;
}

.eng {
  font-family: "minion-pro", serif;
  font-weight: 400;
  font-style: normal;
}

.flex {
  display: flex;
}

.flex-center{
  justify-content: center;
  align-items: center;
}

.bg-brown {
  background-color: #EBE7DC;
}

.bg-white {
  background-color: #FFFFFF;
}

.card {
  padding: 1rem 1rem;
  border-radius: 3px;
}

h2 {
  color: #BCB0B0;
  font-size: 1.8rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
  padding: 0.5rem 0rem;
}

.home span {
  width: 100%;
}

/* animation */
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeup {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.fadein {
  animation: fadein 2s ease-in-out 0s both;
}

.fadein-delay {
  animation: fadein 1.5s ease-in-out 2s both;
}

.fadeup {
  animation: fadeup 1.5s ease-in-out 0s both;
}


/* main-visual */
@keyframes infinity-scroll-left {
from {
  transform: translateX(0);
}
  to {
  transform: translateX(-100%);
}
}

.main-visual {
  margin-bottom: 1rem;
  position: relative;
}

.header-image {
  margin-bottom: 1rem;
  position: relative;
}

.scroll-infinity__list {
  display: flex;
  overflow: hidden;
}

.main-visual .first_view_image {
  height: 100vh;
  animation: infinity-scroll-left 80s infinite linear 0s both;
}

.header-image .first_view_image {
  width: 100vw;
  animation: infinity-scroll-left 80s infinite linear 0s both;
}

.header-image .first_view_image_pc {
  width: 100%;
  animation: infinity-scroll-left 80s infinite linear 0s both;
}

.main-visual .text-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.header-image .text-box {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.main-visual .text-box .first_view_title {
  width: 60vw;
}

.main-visual .text-box .first_view_title_pc {
  width: min(270px, 60vw);
}

.header-image .text-box .first_view_title {
  width: 40vw;
}

.header-image .text-box .first_view_title_pc {
  width: min(180px, 40vw);
}

.main-visual p {
  font-family: "minion-pro", serif;
  font-weight: 600;
  font-style: normal;
  color: white;
}


.main-visual .text-box .name{
  font-size: 1.8rem;
  margin: 0rem;
}


.main-visual .text-box .date{
  font-size: 1.3rem;
  margin: 0rem;
}

.scroll-down {
  position: absolute;
  bottom: 60px;
  right: 30px;
}

.scroll-down span {
  position: absolute;
  color: #FFFFFF;
  font-size: 1rem;
  left: 0px;
  top: 0px;
  font-family: "minion-pro", serif;
  font-weight: 400;
  font-style: normal;
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
}

.scroll-down::before {
  content: "";
  position: absolute;
  top: 0;
  left: -3px;
  width: 8px;
  height: 8px;
  background-color: #FFFFFF;
  border-radius: 50%;
  animation: circlemove 1.6s ease-in-out infinite, circlemovehide 1.6s ease-out infinite;
}

@keyframes circlemove {
  0% {
    transform: translateY(5);
  }
  100% {
    transform: translateY(40px);
  }
}

@keyframes circlemovehide {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}

.scroll-down:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 50px;
  background-color: #FFFFFF;
}

/* message */
.message {
  padding: 0rem 1rem;
  background-color: #FFFFFF;
  margin-bottom: 1rem;
}

.message span {
  width: 100%;
}

.message .main-message {
  text-align: center;
}

.message .main-message p{
  font-size: 0.8rem;
  margin: 1.2rem 0rem;
}

.message .main-message .title{
  font-size: 0.9rem;
}

.message .bride {
  justify-content: center;
  position: relative;
  align-items: center;
  margin: 3rem 0rem;
  width: 100%;
}

.message .groom {
  justify-content: center;
  position: relative;
  align-items: center;
  margin: 3rem 0rem;
  width: 100%;
}

.message h3 {
  font-size: 1.5rem;
  margin: 0rem;
  padding: 0 1rem;
}

.message .groom h3 {
  text-align: right;
}

.message .bride .name {
  position: absolute;
  top: 0%;
  left: 90%;
  width: 50%;
  transform: translate(-100%, 0%);
}

.message .groom .name {
  position: absolute;
  top: 0%;
  left: 10%;
  width: 50%;
}

.message .bride .introduction {
  font-size: 0.7rem;
  padding-left: 1rem;
}

.message .groom .introduction {
  font-size: 0.7rem;
  padding-right: 1rem;
  text-align: right;
}

.message .comment {
  margin-top: 1rem;
  text-align: center;
  font-size: 0.8rem;
}

.message img {
  width: 30%;
  height: auto;
}

/* event */
.event {
  padding: 0rem 1rem;
}

.event span {
  width: 100%;
}

.event h3 {
  text-align: center;
  font-weight: 700;
  margin-bottom: 0rem;
}

.event p, .event a {
  display: block;
  text-align: center;
  font-size: 0.9rem;
  margin: 0rem 0rem;
}

.event .small {
  font-size: 0.7rem;
}

.event .caution {
  margin-top: 3rem;
  font-size: 0.7rem;
}

.event .caution strong {
  margin-top: 3rem;
  font-size: 0.8rem;
  font-weight: 700;
  color: #DB6B5C;
}

/* access */
.access {
  padding: 0rem 1rem;
  padding-bottom: 3rem;
}

.access span {
  width: 100%;
}

.access .map {
  width: 100%;
}

.access a.map{
  display: block;
  text-align: right;
  font-size: 0.7rem;
}

.access p {
  text-align: center;
  font-size: 0.8rem;
  margin: 1.5rem 0rem;
}

.access .weather-info {
  text-align: center;
  font-size: 0.8rem;
  margin: 1.5rem 0rem;
}

.access .weather-info h3 {
  font-size: 0.9rem;
  font-weight: 700;
}

.access .weather-info .temperature {
  display: flex;
  justify-content: center;
}

.access .weather-info .temperature .highest, .access .weather-info .temperature .lowest{
  margin: 0 2rem;
}

.weather-info p {
  margin: 0 0 0 0;
}

.temperature span {
  font-size: 0.9rem;
  font-weight: 700;
}

.temperature strong {
  font-size: 2rem;
  font-weight: 700;
}

.temperature .highest span, .temperature .highest strong {
  color: #DB6B5C;
}

.temperature .lowest span, .temperature .lowest strong {
  color: #60A5D6;
}

/* tab */
.react-tabs__tab-list {
  display: flex;
  list-style: none;
  padding: 0 0;
  text-align: center;
}

.react-tabs__tab {
  border-bottom: 1px solid #EBE7DC;
  width: 50%;
  padding: 0.5rem 1rem 0rem 1rem;
  font-size: 0.8rem;
  font-weight: 700;
  cursor: pointer;
  margin: 0rem 0rem;
  color: #C6C0B2;
}

.react-tabs__tab--selected {
  border-bottom: 1px solid #8B8975;
  color: #8B8975;
  font-weight: 700;
}

/* access-detail */
.access-detail .details span{
  position: relative;
  display: block;
  width: 45%;
}

.access-detail .details .node{
  margin: 1rem auto;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 700;
}

.access-detail .details .link{
  font-size: 0.7rem;
  left: 55%
}

.access-detail .details .link::before{
  content: "";
  position: absolute;
  top: -50%;
  left: -5%;
  width: 1px;
  height: 200%;
  background-color: #EBE7DC;

}

.access-detail .caption {
  margin: 1rem 0rem;
  text-align: center;
  font-size: 0.8rem;
}

.access-detail .caption strong{
  font-size: 0.9rem;
  font-weight: 700;
}

/* announce */
.announce p{
  text-align: center;
  font-size: 0.9rem;
  margin: 3rem 0rem;
}

.anounce span {
  width: 100%;
}

.announce strong{
  font-size: 1.2rem;
  font-weight: 700;
}



/* faq */
.faq {
  padding: 0rem 1rem;
  margin-bottom: 1rem;
}

.faq span {
  width: 100%;
}

.faq p {
  text-align: center;
  font-size: 0.8rem;
  margin: 0rem 0rem;
}

.faq h3 {
  text-align: left;
  font-size: 0.9rem;
  margin-left: 0.5rem;
  font-weight: 700;
}


.faq details {
  margin: 0.5rem 0rem;
  padding: 0rem 1rem;
  font-size: 0.8rem;
  display: inline-block;
  list-style: none;
}

.faq details summary {
  font-weight: 700;
  cursor: pointer;
  margin: 0rem 0rem;
  padding-left: 0rem;
  border-bottom: #EBE7DC 1px solid;
  display: -webkit-box;
  list-style: none;
}

.faq details summary .question {
  display: flex;
}

.faq details summary .question svg{
  width: 1.5rem;
  margin: auto 0.2rem;
  vertical-align: middle;
}

.faq details summary p {
  text-align: left;
  font-weight: 700;
  font-size: 0.8rem;
  cursor: pointer;
  padding-left: 0.2rem;
  margin: 0rem 0rem;
}

.faq details .answer p {
  text-align: left;
  padding-left: 0.3rem;
  margin-top: 0.3rem;
  font-size: 0.7rem;
}



/* karuizawa */
.karuizawa {
  padding: 0rem 1rem;
  margin-bottom: 1rem;
}

.karuizawa span {
  width: 100%;
}

.karuizawa p {
  text-align: center;
  font-size: 0.8rem;
  margin: 0rem 0rem;
}

.mymap {
  width: 100%;
  height: 50vh;
}

/* rsvp */
.rsvp {
  padding: 0rem 1rem;
  margin-bottom: 1rem;
}

.rsvp span {
  width: 100%;
}

.rsvp .rsvp-form, .confirm .rsvp-form {
  margin: 0rem 2rem;
}

.rsvp p {
  font-size: 0.8rem;
  margin: 0rem 0rem;
  text-align: center;
}


.rsvp label {
  display: block;
  text-align: left;
  font-size: 0.8rem;
  font-weight: 700;
  margin: 0rem 0rem;
}

.rsvp-form p {
  font-size: 0.6rem;
  margin: 0rem 0rem;
  text-align: left;
}

.rsvp-form .radio {
  display: flex;
  justify-content: center;
  margin: 1rem 0rem;
  ;
}

.rsvp-form .radio label.attendance {
  font-size: 1rem;
  margin: 0rem 1rem;
}

.rsvp-form .radio label {
  cursor: pointer;
  padding-left: 30px;
  position: relative;
}


.rsvp-form .radio label::before,
.rsvp-form .radio label::after {
  content: "";
  display: block; 
  position: absolute;
}

.rsvp-form .radio label::before {
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #EBE7DC;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  top: 50%;
  left: 5px;
}

.rsvp-form .radio label::after {
  border-bottom: 2px solid #a5a5a5;
  border-left: 2px solid #a5a5a5;
  opacity: 0;
  height: 5px;
  width: 10px;
  transform: rotate(-45deg);
  top: 10px;
  left: 10px;
}

.rsvp-form .radio input:checked +  label:after {
  opacity: 1;
}

.rsvp input {
  display: block;
  width: 90%;
  height: 0.6rem;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0.5rem;
  border: 1px solid #EBE7DC;
  border-radius: 3px;
  font-size: 0.8rem;
}

.rsvp select {
  display: block;
  width: 90%;
  height: 1.7rem;
  margin: 0.5rem auto;
  border: 1px solid #EBE7DC;
  background-color: #fff;
  border-radius: 3px;
  font-size: 0.8rem;
}

.rsvp textarea {
  display: block;
  width: 90%;
  height: 4rem;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0.5rem;
  border: 1px solid #EBE7DC;
  border-radius: 3px;
  font-size: 0.8rem;
}

.rsvp .add-form {
  margin: 1rem 0rem;
  height: 2rem;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 700;
}

.rsvp .companion, .confirm .companion {
  border-top: #EBE7DC 1px solid;
  margin-top: 1rem;
  padding-top: 1rem;
}

.rsvp .privacy {
  margin: 1rem 0rem;
  text-align: center;
  font-size: 0.8rem;
}

.confirm {
  margin: 1rem 0rem;
  text-align: center;
  padding: 0rem 1rem;
}

.confirm p {
  font-size: 0.8rem;
  margin: 0rem 0rem;
}

.confirm label {
  display: block;
  text-align: left;
  font-size: 0.8rem;
  font-weight: 700;
  margin: 0rem 0rem;
}

.confirm .rsvp-form {
  margin-bottom: 1rem;
}

.complete {
  margin: 1rem 0rem;
  text-align: center;
}

.complete p {
  font-size: 0.8rem;
  margin: 0rem 0rem;
}

.complete .complete-message {
  font-size: 0.9rem;
  font-weight: 700;
  margin: 3rem 0rem;
}

/* footer */
.footer {
  padding: 2rem;
  font-size: 15px;
  color: #999;
  background: #fff;
  border-top: 1px solid #e5e7eb;
}

.footer a {
  text-decoration: none;
  font-size: 0.7rem;
}

.footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer p {
  margin: 1rem 0rem;
  padding: 0;
  text-align: center;
  font-size: 0.7rem;
}


.buttom-round {
  border-radius: 30px;
  width: 60%;
  height: 2rem;
  background-color: #DBBA8B;
  text-align: center;
  margin: 1rem auto;
}

.disable {
  opacity: 0.4;
}

.buttom-round a{
  font-size: 0.8rem;
  font-weight: 700;
  color: #FFFFFF;
  text-decoration: none;
}

.tips{
  margin:2em 0;
  position: relative;
  padding: 0.5em 1.5em;
  border-top: solid 2px #EBE7DC;
  border-bottom: solid 2px #EBE7DC;
}
.tips:before, .tips:after{
  content: '';
  position: absolute;
  top: -10px;
  width: 2px;
  height: -webkit-calc(100% + 20px);
  height: calc(100% + 20px);
  background-color: #EBE7DC;
}
.tips:before {left: 10px;}
.tips:after {right: 10px;}
.tips p {
  margin: 0; 
  padding: 0;
  font-size: 0.7rem;
}

.notfound {
  text-align: center;
  font-size: 0.8rem;
}

.privacy-modal {
  position: absolute;
  width: min(80vw, 360px);
  height: 60vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 20%);
  padding: 1rem;
  border-radius: 10px;
  background-color: white;
  display: block;
  margin: auto auto;
}

.privacy-modal h3{
  font-size: 1rem;
  font-weight: 700;
  margin: 0rem 0rem;
}

.privacy-modal h4{
  font-size: 0.8rem;
  font-weight: 700;
  margin: 1rem 0rem;
  border-bottom: #8B8975 1px solid;
}

.privacy-modal p{
  font-size: 0.6rem;
  margin: 1rem 0rem 1rem 0rem;
  list-style: none;
  padding: 0rem 0.5rem 0rem 0.5rem;
}

.privacy-modal li, .privacy-modal ul{
  font-size: 0.6rem;
  margin: 1rem 0rem 0rem 0rem;
  list-style: none;
  padding: 0rem 0rem 0rem 0.5rem;
}

.privacy-modal-content {
  overflow: scroll;
  height: 90%;
}

.close-modal {
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
  color: #8B8975;
  cursor: pointer;
}

/* menu */
@keyframes bugfix {
  from {
    padding: 0;
  }
  to {
    padding: 0;
  }
}
@-webkit-keyframes bugfix {
  from {
    padding: 0;
  }
  to {
    padding: 0;
  }
}


#overlay-button {
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  padding: 26px 11px;
  z-index: 1000;
  cursor: pointer;
  user-select: none;
}
#overlay-button span {
  height: 2px;
  width: 20px;
  border-radius: 1px;
  background-color: #8B8975;
  position: relative;
  display: block;
  transition: all .2s ease-in-out;
}
#overlay-button span:before {
  top: -7px;
  visibility: visible;
}
#overlay-button span:after {
  top: 7px;
}
#overlay-button span:before, #overlay-button span:after {
  height: 2px;
  width: 20px;
  border-radius: 1px;
  background-color: #8B8975;
  position: absolute;
  content: "";
  transition: all .2s ease-in-out;
}
#overlay-button:hover span, #overlay-button:hover span:before, #overlay-button:hover span:after {
  background: #8B8975;
}

.outer-menu input[type=checkbox] {
  display: none; 
  
}

.outer-menu input[type=checkbox]:checked ~ #overlay {
  visibility: visible; 
}

.outer-menu input[type=checkbox]:checked ~ #overlay-button:hover span, .outer-menu input[type=checkbox]:checked ~ #overlay-button span {
  background: transparent;
}

.outer-menu input[type=checkbox]:checked ~ #overlay-button span:before {
  transform: rotate(45deg) translate(3px, 3px);
  color: #fff;
}
.outer-menu input[type=checkbox]:checked ~ #overlay-button span:after {
  transform: rotate(-45deg) translate(7px, -7px);
  color: #fff;

}

#overlay {
  height: 100vh;
  width: 100vw;
  background: #8B8975;
  z-index: 2;
  visibility: hidden;
  position: fixed;
  opacity: 0.95;
}
#overlay ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 100vh;
  padding-left: 0;
  list-style-type: none;
}
#overlay ul li {
  padding: 1em;
}
#overlay ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 0,8em;
  font-weight: 700;
}
#overlay ul li a:hover {
  color: #BCB0B0!important;
  text-decoration: none;
  font-weight: 700;
}

/* line-official */
.line-official {
  padding: 0rem 1rem;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 0.9rem;
  margin: 3rem 0rem;
}

.line-official span {
  width: 100%;
}

.line-official p{
  padding: 0rem 1rem;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 0.9rem;
}

.visually-hidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%); 
  margin: -1px;
}